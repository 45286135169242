import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';

import { promiseScript, mightBeEmail, toTitleCase } from '../utils';

const loadGoogleAnalyticsIfNeeded = function(id, dimensions, config) {
  return new Promise((resolve, reject) => {
    if (typeof window.gtag === 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function() {
        window.dataLayer.push(arguments);
      };

      window.gtag('js', new Date());
      window.gtag('set', {
        'custom_map': dimensions,
      });
      window.gtag('config', id, {
        ...config,
        'anonymize_ip': true,
      });

      promiseScript('gtag', `https://www.googletagmanager.com/gtag/js?id=${id}`)
        .then(resolve)
        .catch(reject);
    } else {
      resolve();
    }
  });
};

class Analytics {
  /**
   * Initialize Google Analytics
   * @param opts.dimensions {Object} optional
   * @param opts.googleOptions {Object} optional
   * @param opts.ignorePageView {boolean} optional
   */
  constructor({ env, logger, config }) {
    this.logger = logger;
    this.measurementId = config.tracking_id;
    this.dimensions = config.dimensions;
    this.pageDimensions = window.bpAnalyticsDimensions || {};

    loadGoogleAnalyticsIfNeeded(this.measurementId, this.dimensions, {
      ...this.pageDimensions
    })
      .then(() => this.logger.info('initialized Google Analytics'))
      .catch((err) => this.logger.error('failed to load Google Analytics', err));
  }

  gtag() {
    if (arguments.length > 0) {
      window.gtag.apply(this, arguments);
      return;
    }

    return window.gtag;
  }

  pageView(path) {
    if (isEmpty(path)) {
      this.logger.warn('path is required in .pageView()');
      return;
    }

    const trimmedPath = trim(path);

    this.logger.info(`ga page view: ${trimmedPath}`);
    this.gtag('config', this.measurementId, { 'page_path': trimmedPath });
  }

  format(s, titleCase = false) {
    if (mightBeEmail(s)) {
      this.logger.warn('This arg looks like an email address, redacting.');
      return 'REDACTED (Potential Email Address)';
    }

    if (titleCase) {
      return toTitleCase(s);
    }

    return s;
  }

  /**
   * Track an analytics event
   * @param args.category {String} required
   * @param args.action {String} required
   * @param args.label {String} optional
   * @param args.value {int} optional
   */
  event(args) {
    if (!args || !args.category || !args.action) {
      this.logger.warn('args.category AND args.action are required in .event()');
      return;
    }

    const eventAction = this.format(args.action);
    const fields = {
      event_category: this.format(args.category),
    };

    if (args.label) {
      fields.event_label = this.format(args.label);
    }

    if (args.hasOwnProperty('value')) {
      if (typeof args.value !== 'number') {
        this.logger.warn('expected args.value to be a Number in .event()');
      } else {
        fields.value = args.value;
      }
    }

    this.logger.info(`ga event: `, { event_action: eventAction, ...fields });
    this.gtag('event', eventAction, fields);
  }
}

const google = (opts) => (
  new Analytics(opts)
);

export default google;
