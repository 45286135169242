import { promiseScript } from '../utils'

const bpa = ({ env, logger, config }) => {
  window.bpa = window.bpa || function() { (window.bpa.q = window.bpa.q || []).push(arguments) }

  promiseScript('bpa', `https://${config.endpoint}/bpa.js`, {
    'data-domain': config.domain,
  })
    .then(() => logger.info('initialized BabyPips Analytics'))
    .catch((err) => logger.warn('failed to load BabyPips Analytics', err));

  return {
    event(opts) {
      const { category, action, label, payload } = opts || {};

      if (!category || !action) {
        logger.warn('cannot report event; must define category and action');
        return;
      }

      const name = `${category}/${action}`;
      const props = {
        label,
        ...(payload || {}),
      };

      window.bpa(name, props);
    },
  };
};

export default bpa;
