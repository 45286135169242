const logger = ({ env, logger }) => {
  if (env === 'production') {
    return;
  }

  return {
    event(opts) {
      const { category, action, label, payload } = opts || {};

      if (!category || !action) {
        logger.warn('cannot report event; must define category and action');
        return;
      }

      const name = `${category}/${action}`;
      const props = {
        ...(payload || {}),
      };

      if (label) {
        props.label = label;
      }

      logger.info(name, props);
    },
  };
};

export default logger;

