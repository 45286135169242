import { promiseScript } from '../utils'

const adblock = ({ env, logger }) => {
  let gkm;

  function report(gkm) {
    const r = new XMLHttpRequest();
    r.open('POST', '/ndbgkm');
    r.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    r.send('ndb=' + gkm);
  }

  function cop() {
    setTimeout(() => {
      const elem = document.getElementById('onolcvcfpbz');

      if (elem) {
        const style = window.getComputedStyle(elem);
        gkm = (style.display === 'none' || elem.offsetParent === null) ? 1 : 0;
      } else {
        gkm = 1;
      }


      if (gkm === 1) {
        logger.warn('AdBlock says ads are %cblocked', 'font-style: italic; color: red;');
      } else {
        logger.debug('AdBlock says ads are %cnot blocked', 'font-style: italic; color: green;');
      }

      report(gkm);
    }, 100);
  }

  promiseScript('adblock', '/ads.js')
    .then(() => logger.info('initialized AdBlock Analytics'))
    .then(() => cop())
    .catch(() => {
      gkm = 1;
      logger.warn('AdBlock says ads are %cblocked', 'font-style: italic; color: red;');
      report(gkm);
    });
};

export default adblock;
