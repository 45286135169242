import debug from 'debug';

const base = 'toddlerpips';
const colors = {
  trace: 'lightblue',
  info: 'blue',
  warn: 'pink',
  error: 'red',
};

class Logger {
  constructor({ prefix } = {}) {
    this.prefix = prefix;
  }

  generateMessage(level, message, args) {
    const namespace = `${base}:${level}`;
    const createDebug = debug(namespace);
    createDebug.color = colors[level];

    if (this.prefix) {
      message = `%c${this.prefix} %c${message}`;
      args = ['font-weight: bold;', 'font-weight: normal', ...args];
    }

    if (args && args.length > 0) {
      createDebug(message, ...args);
    } else {
      createDebug(message);
    }
  }

  enable() {
    if (localStorage) {
      localStorage.setItem('debug', `${base}:*`);
    }
  }

  debug(message, ...args) {
    this.generateMessage('trace', message, args);
  }

  info(message, ...args) {
    this.generateMessage('info', message, args);
  }

  warn(message, ...args) {
    this.generateMessage('warn', message, args);
  }

  error(message, ...args) {
    this.generateMessage('error', message, args);
  }
}

const logger = new Logger();
export default logger;

export function prefixedLogger(prefix) {
  return new Logger({ prefix });
}
