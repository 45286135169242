import { prefixedLogger } from './logger';
import configs from '../../../config/settings/analytics.yml';

export default class ThirdPartyManager {
  constructor(prefix) {
    this.logger = prefixedLogger(prefix);
    this.opts = {
      env: process.env.BP_ENV,
      logger: this.logger,
    };
  }

  initializeBackends(backends) {
    this.backends = Object.keys(backends).map((b) => {
      const backend = backends[b];
      const config = configs[b.toLowerCase()];

      if (config !== undefined && config?.enabled === false) {
        this.logger.warn(`${b} is disabled`);
        return false;
      }

      return this.initializeBackend(backend, config);
    }).filter((b) => Boolean(b));
  }

  initializeBackend(backend, config = {}) {
    try {
      return backend({
        ...this.opts,
        config,
      });
    } catch(ex) {
      this.logger.warn(`Failed to initialize '${backend}'`, ex);
    }
  }

  invoke(method, ...args) {
    try {
      for (const idx in this.backends) {
        const backend = this.backends[idx];

        if (typeof backend !== 'undefined' && typeof backend[method] === 'function') {
          backend[method](...args);
        }
      }
    } catch (err) {
      this.logger.error(`Could not invoke '${method}'`, err);
    }
  }
}
