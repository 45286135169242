import ThirdPartyManager from './thirdPartyManager';

import Logger from './analytics/logger';
import BPA from './analytics/bpa';
import Plausible from './analytics/plausible';
import Admiral from './analytics/admiral';
import Google from './analytics/google';
import Clicky from './analytics/clicky';
import Hotjar from './analytics/hotjar';
import Survicate from './analytics/survicate';
import AdBlock from './analytics/adblock';
import ActiveCampaign from './analytics/activecampaign';

class Manager extends ThirdPartyManager {
  constructor() {
    super('[analytics]');
  }

  initialize() {
    this.initializeBackends({
      Logger,
      BPA,
      Plausible,
      Google,
      Clicky,
      Hotjar,
      Survicate,
      Admiral,
      AdBlock,
      ActiveCampaign,
    });
  }

  event(args) {
    this.invoke('event', args);
  }

  pageView(path) {
    this.invoke('pageView', path);
  }
}

const manager = new Manager();
export default manager;

/**
 * Track an analytics event
 * @param args.category {String} required
 * @param args.action {String} required
 * @param args.label {String} optional
 * @param args.value {int} optional
 * @param args.nonInteraction {boolean} optional
 * @param args.transport {String} optional
 */
export function trackEvent(args) {
  manager.event(args);
}

/**
 * Track a page view
 * @param path {String} required
 */
export function trackPageView(path) {
  manager.pageView(path);
}
