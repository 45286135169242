import { promiseScript } from '../utils'

const ac = ({ env, logger, config }) => {
  const e = window;
  const n = 'vgo';

  e.visitorGlobalObjectAlias = n;
  e[e.visitorGlobalObjectAlias] = e[e.visitorGlobalObjectAlias] || function () {
    (e[e.visitorGlobalObjectAlias].q = e[e.visitorGlobalObjectAlias].q || []).push(arguments);
  };
  e[e.visitorGlobalObjectAlias].l = new Date().getTime();

  e.vgo('setAccount', config.id);
  e.vgo('setTrackByDefault', true);
  e.vgo('process');

  promiseScript('activecampaign', 'https://diffuser-cdn.app-us1.com/diffuser/diffuser.js')
    .then(() => logger.info('initialized ActiveCampaign'))
    .catch((err) => logger.warn('failed to load ActiveCampaign', err));
};

export default ac;
