import { promiseScript } from '../utils'

const clicky = ({ env, logger, config }) => {
  if (env === 'production') {
    window['clicky_site_ids'] = [config.site_id];

    promiseScript('clicky', 'https://static.getclicky.com/js')
      .then(() => logger.info('initialized Clicky'))
      .catch((err) => logger.warn('failed to load Clicky', err));
  } else {
    logger.info(`skipping Clicky in ${env}`);
  }
};

export default clicky;