import { promiseScript } from '../utils'

const survicate = ({ logger, config }) => {
  (function(opts) {
    opts.traits = {
      user_id: window.bpUserTraits.survicate_id,
      is_premium: window.bpUserTraits.is_premium,
      is_user: window.bpUserTraits.is_user,
      is_guest: window.bpUserTraits.is_guest,
    };
  })(window._sva = window._sva || {});

  promiseScript('survicate', `https://survey.survicate.com/workspaces/${config.workspace}/web_surveys.js`)
    .then(() => logger.info('initialized Survicate with traits', window._sva.traits))
    .catch(() => logger.warn('failed to load Survicate'));
};

export default survicate;
