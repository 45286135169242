import { promiseScript } from '../utils'

const hotjar = ({ env, logger, config }) => {
  window.hj = window.hj || function() {
    (window.hj.q = window.hj.q || []).push(arguments);
  };

  window['_hjSettings'] = { hjid: config.id, hjsv: 6 };

  promiseScript('hotjar', `https://static.hotjar.com/c/hotjar-${config.id}.js?sv=6`)
    .then(() => {
      logger.info('initialized Hotjar');
    })
    .catch(() => {
      logger.warn('failed to load Hotjar');
    });
};

export default hotjar;
